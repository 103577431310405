.typo-header-pink {
  color: $pink;
  text-transform: uppercase;
  //text-align: center;
  font-weight: bold;
  font-size: 26px;
}

.typo-header-product {
  color: $pink;
  font-weight: bold;
  font-size: 18px;
}