.product {

  .information {
    @extend .row;
    margin-bottom: 30px;

    .description {
      @include make-md-column(6);
    }

    .thumb {
      @include make-md-column(6);

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .rent-now {
    text-align: center;
    margin-bottom: 30px;

    & + .rent-now {
      display: none;
    }
  }

  .extra-content {
    margin-bottom: 30px;
  }

  .rentalblocks {
    @include make-row();
    margin-bottom: 30px;

    .block {
      @include make-md-column(6);
      margin-bottom: 30px;

      .inner {
        position: relative;
        background-color: $silver-light;
        min-height: 100px;

        padding: 15px 30px;
      }

      .free-miles {
        position: absolute;
        background-color: $pink;
        color: white;

        right: 30px;
        top: 15px;
        width: 60px;
        height: 60px;

        padding-top: 14px;

        text-align: center;
        line-height: 16px;
        font-size: 11px;

        border-radius: 30px;
        transform: rotate(10deg);
      }

      h3 {
        @extend .typo-header-product;
        margin: 15px 0 5px 0;
      }

      .meta {
        @extend .row;
        margin-bottom: 10px;
        line-height: 24px;
        padding-right: 60px;

        @media(min-width: $screen-sm-min)
        {
          padding-right: 0;
        }

        .period, .price {
          @include make-md-column(6)
        }

        .period {
          line-height: 26px;
        }

        .price {
          @media (min-width: $screen-sm) {
            padding-left: 0;
          }
          line-height: 24px;

          span {
            line-height: 24px;
            @extend .typo-header-product;
            vertical-align: baseline;
          }
        }
      }

      table {
        width: 100%;
        background-color: $silver;
      }

      tr:nth-child(even) {
        background-color: $gray;
      }

      td {
        padding: 5px 15px;
      }
    }
  }

  .more-info {
    display: inline-block;
    width: 100%;
    background-color: $silver-light;
    padding: 30px 15px;
    margin-bottom: 30px;

    h3 {
      @extend .typo-header-product;
      color: #000;
      @include make-md-column(12);
      margin-top: 0;
      margin-bottom: 20px;
    }

    .eigenschappen {
      @include make-md-column(6);
    }

    table {
      width: 100%;
      background-color: $silver;

      tr:nth-child(even) {
        background-color: $gray;
      }

      td {
        width: 50%;
        padding: 5px 15px;
      }
    }
  }
}