#frontpage-promotions {
  background-color: #ec60aa; //$pink;
  background-image: url(../img/frontpage-promotions-bg.jpg);
  background-repeat: no-repeat;
  background-position: right top;
  text-align: center;

  padding: 30px;
  margin-bottom: 30px;

  color: white;

  h1 {
    @extend .typo-header-pink;
    color: inherit;
    margin: 0 0 30px 0;
  }

  .textwidget {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0 15px 0;
  }
}