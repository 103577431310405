#frontpage-selector-main, #frontpage-selector-rentals {
  ul {
    @extend .row;
    list-style: none;
    padding: 0;

    a {
      display: block;
      background-color: $silver-light;
      text-align: center;
      padding: 10px;
      border-radius: 7px;
      color: black;
      //max-height: 50px;
    }
  }

  li {
    @extend .col-sm-6;
    @extend .col-md-3;

    margin-bottom: 30px;
  }

  figure {
    display: block;
    margin: 15px 20px;
    padding: 15px 35px; // 25px 10px 25px;
    background-color: $gray;
    border-radius: 7px;

    figcaption {
      margin: 0 -15px;
    }

    img, svg {
      width: 100%;
      height: auto;
      max-height: 175px;

      @media (min-width: $screen-sm-min) {
        //max-height: none;
      }
    }
  }
}

#frontpage-selector-main {
  ul {
    margin-bottom: 30px;
  }

  li {
    strong {
      color: $pink;
      font-size: 18px;
    }
  }

  a {
    border: solid 1px $silver;

    &:hover {
      border-color: $pink;
      text-decoration: none;
      color: inherit;
    }
  }

  p.btn {
    white-space: normal;
  }
}

#frontpage-selector-rentals {

  /**
   * Styling van divider + pijl naar beneden
   */
  header {
    background-color: $silver-light;
    text-align: center;
    margin-bottom: 30px;

    h5 {
      text-transform: uppercase;
      color: $pink;
      font-weight: bold;
      font-size: 12px;
      line-height: 30px;
      margin: 0;
    }

    .divider {
      background-color: $gray;
      height: 30px;

      img {
        margin-top: -5px;
        box-shadow: 2px 2px 3px 0 #666;
        border-radius: 20px;
      }
    }
  }

  li {
    margin-bottom: 30px;
  }

  a {
    &:hover {
      figure {
        background-color: $pink;
        //background-color: $green;
        color: white;
      }
      svg path, svg polygon, svg circle {
        fill: #fff;
      }
    }
  }

  /**
   * Laatste contacteer ons regel
   */
  .contact-us {
    text-align: center;
    margin-bottom: 30px;

    p:last-child {
      margin-bottom: 0;
    }
  }
}