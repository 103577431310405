article.page {
  margin-bottom: 30px;

  h1 {
    @extend .typo-header-pink;
    margin: 0;
    margin-bottom: 30px;
  }

  h2, h3 {
    color: $pink;
    font-weight: bold;
    margin-bottom: 5px;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 18px;
  }

}