#stores-listing {
  margin-bottom: 15px;
  border-top: solid 1px #000;

  h2 {
    @extend .typo-header-pink;
    text-align: center;
    margin: 30px 0;
  }

  ul {
    @extend .row;
    list-style: none;
    padding: 0;
  }

  li {
    display: block;

    @extend .col-sm-3;
    @extend .col-xs-6;

    vertical-align: top;

    & > img {
      margin-bottom: 15px;
    }
  }

  strong {
    color: $pink;
  }

  .store-description, .store-businesshours {
    display: block;
    margin-bottom: 15px;
  }
}