footer {
  @extend .row;
  font-size: 12px;

  &#stores {
    img {
      width: 100%;
      height: auto;
    }
  }

  &#colofon {
    @extend .row;

    padding: 15px 20px 0 20px;

    background-color: $pink;
    color: white;

    .links, .address {
      @extend .col-md-6;
      padding-bottom: 15px;
    }

    /**
     * Vanaf resolutie voor tablet
     */
    @media (min-width: $screen-md) {
      .address {
        text-align: right;
      }
    }

    ul {
      @extend .list-inline;
      margin: 0;

      & > li {
        padding: 0 10px 0 0;

        &:after {
          content: "|";
          padding-left: 10px;
        }

        &:last-child:after {
          content: "";
        }
      }
    }

    a {
      color: inherit;
      text-transform: lowercase;
    }
  }
}