header.banner {
  @extend .row;
  position: relative;

  .logo {
    padding: 15px 35px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  @media (min-width: $screen-sm) {
    padding-bottom: 50px;
    min-height: 260px; //310px;

    background-image: url(../img/header_bg.png);
    //background-size: contain;
    background-repeat: no-repeat;

    .logo {
      position: relative;
      height: 215px;

      img {
        position: absolute;
        right: 45px;
        bottom: 30px;
      }
    }
  }
}