.page {

  table {
    &.storage {
      background-color: $silver-light;
      margin-bottom: 10px;

      .header-background {
        background: $pink;
        color: white;
      text-transform: uppercase;
      }

      th, td {
        padding: 10px;
        text-align: center;
      }

      tbody {
        background-color: $silver;

        tr:nth-child(even) {
          background-color: $gray;
        }
      }
    }
  }
}