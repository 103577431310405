div.categories {
  @extend .list-inline;
  @include make-row();

  .item {
    @include make-md-column(6);
    margin-bottom: 20px;

    .item-inner {
      background-color: $silver-light;
      display: inline-block;
      width: 100%;
      min-height: 150px;
      //@include make-row();
    }

    .thumb {
      @include make-md-column(4);

      padding: 0 45px;
      @media (min-width: $screen-sm-min) {
        padding: 0 15px;
      }

      img {
        width: 100%;
        height: auto;
        padding: 10px;
      }
    }

    aside {
      @include make-md-column(8);
      padding-bottom: 15px;

      h3 {
        @extend .typo-header-product;
        margin-top: 0;

        @media (min-width: $screen-sm-min) {
          margin-top: 20px;
        }


        a {
          color: inherit;
        }
      }
    }
  }

}