.overview {
  margin-bottom: 30px;

  .item {
    display: inline-block;
    background-color: $silver-light;
    width: 100%;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    .thumb {
      @include make-md-column(3);
      margin: 15px 0;

      img {
        width: 100%;
        height: auto;

        border-radius: $rounded-borders;
      }
    }

    .description {
      @include make-md-column(6);
      margin-bottom: 15px;

      h3 {
        @extend .typo-header-product;
        margin-top: 15px;

        a {
          color: inherit;
        }
      }

      table {
        background-color: $silver;
        width: 100%;

        tr:nth-child(even) {
          background-color: $gray;
        }

        td {
          width: 50%;
          padding: 5px 20px;
          font-weight: normal;
        }
      }
    }

    aside {
      @include make-md-column(3);

      .inner {
        background-color: $gray;
        margin: 15px 0;
        padding: 15px 20px;

        border-radius: $rounded-borders;
      }

      .teaser {
        .price {
          @extend .typo-header-product;
          text-transform: uppercase;
        }
      }

      .btn {
        width: 100%;
        margin-top: 10px;
      }

      .btn-transparent {
        color: $pink;
        border-color: $pink;
      }
    }
  }
}