/* jQuery Lighter
 * Copyright 2015 Kevin Sylvestre
 * 1.3.4
 */
.lighter {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4000;
  opacity: 1; }
.lighter img {
  display: block;
  width: 100%;
  height: 100%; }
.lighter-fade .lighter-overlay {
  opacity: 0; }
.lighter-loading .lighter-spinner {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }
.lighter-loading .lighter-container {
  opacity: 0;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4); }
.lighter-fetched .lighter-spinner {
  opacity: 0;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4); }
.lighter-fetched .lighter-container {
  opacity: 1;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1); }
.lighter-fade .lighter-container {
  opacity: 0;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4); }
.lighter-fade .lighter-spinner {
  opacity: 0;
  -webkit-transform: scale(0.4);
  -moz-transform: scale(0.4);
  -ms-transform: scale(0.4);
  -o-transform: scale(0.4);
  transform: scale(0.4); }
.lighter-overlay {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transition-property: opacity, -webkit-transform;
  -moz-transition-property: opacity, -moz-transform;
  transition-property: opacity, transform;
  background: rgba(255, 255, 255, 0.8);
  height: 100%;
  width: 100%; }
.lighter-container {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transition-property: opacity, -webkit-transform;
  -moz-transition-property: opacity, -moz-transform;
  transition-property: opacity, transform;
  background: #FFF;
  position: absolute;
  z-index: 4000;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.4); }
.lighter-close {
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  margin: -15px;
  line-height: 28px;
  font-size: 16pt;
  font-family: Helvetica, Arial, serif; }
.lighter-next, .lighter-prev {
  display: none;
  top: 50%;
  width: 40px;
  height: 40px;
  margin: -20px 0;
  line-height: 34px;
  font-size: 32pt;
  font-family: Times, serif; }
.lighter-next {
  right: 40px; }
.lighter-prev {
  left: 40px; }
.lighter-next, .lighter-prev, .lighter-close {
  cursor: pointer;
  position: absolute;
  z-index: 8000;
  text-align: center;
  border-radius: 50%;
  color: rgba(255, 255, 255, 0.8);
  background: rgba(0, 0, 0, 0.2);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transition-property: color, background;
  -moz-transition-property: color, background;
  transition-property: color, background; }
.lighter-next:hover, .lighter-prev:hover, .lighter-close:hover {
  color: white;
  background: rgba(0, 0, 0, 0.4); }
.lighter-next:active, .lighter-prev:active, .lighter-close:active {
  color: white;
  background: rgba(0, 0, 0, 0.4); }
.lighter-spinner {
  -webkit-transition: opacity 0.4s ease-in-out;
  -moz-transition: opacity 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out;
  position: absolute;
  width: 60px;
  height: 20px;
  margin: -10px -30px;
  z-index: 4000;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%; }
.lighter-spinner::after {
  clear: both;
  content: "";
  display: table; }
.lighter-dot {
  display: block;
  float: left;
  background: rgba(0, 0, 0, 0.2);
  width: 16px;
  height: 16px;
  margin: 2px;
  padding: 0;
  border-radius: 50%;
  -webkit-animation: lighter-bounce 1.6s infinite ease-in-out;
  -moz-animation: lighter-bounce 1.6s infinite ease-in-out;
  animation: lighter-bounce 1.6s infinite ease-in-out; }
.lighter-dot:nth-child(1) {
  -webkit-animation-delay: -0.4s;
  -moz-animation-delay: -0.4s;
  animation-delay: -0.4s; }
.lighter-dot:nth-child(2) {
  -webkit-animation-delay: -0.26667s;
  -moz-animation-delay: -0.26667s;
  animation-delay: -0.26667s; }
.lighter-dot:nth-child(3) {
  -webkit-animation-delay: -0.13333s;
  -moz-animation-delay: -0.13333s;
  animation-delay: -0.13333s; }

@-webkit-keyframes lighter-bounce {
  from,to {
    -webkit-transform: scale(0); }
  20% {
    -webkit-transform: scale(0); }
  50% {
    -webkit-transform: scale(1); }
  80% {
    -webkit-transform: scale(0); } }
@-moz-keyframes lighter-bounce {
  from,to {
    -moz-transform: scale(0); }
  20% {
    -moz-transform: scale(0); }
  50% {
    -moz-transform: scale(1); }
  80% {
    -moz-transform: scale(0); } }
@keyframes lighter-bounce {
  from,to {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  20% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); }
  50% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  80% {
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0); } }
