@import "colors";
@import "variables";

@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import "vendor/bower_components/bootstrap-sass/assets/stylesheets/bootstrap";

html, body {
  background-color: $silver;
}

body.admin-bar {
  #mobile-menu {
    top: 46px;
  }
}

main {
  margin-top: 50px;

  @media (min-width: $screen-sm) {
    margin-top: 0;
  }
}

.main-wrapper {
  margin: 0 20px;
}

@import "partials/navigation";
@import "partials/header";
@import "partials/breadcrumbs";
@import "partials/footer";

@import "elements/btn";
@import "elements/typography";
@import "elements/table";
@import "elements/form";
@import "elements/lighter";

@import "pages/frontpage";
@import "pages/page";
@import "pages/rental";
@import "pages/stores";

main {
  @extend .container;
  background-color: white;
}

a.btn {
  white-space: normal;
}