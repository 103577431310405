.gform_wrapper {

  .medium {
    @media (max-width: $screen-xs-max)
    {
      width: 100% !important;
    }
  }

  input[type=text], select, textarea {
    @extend .form-control;
    color: #000;
  }

  input[type=submit] {
    @extend .btn;
    @extend .btn-green;
  }

  .ginput_complex {
    label {
      color: darken($gray, 40);
    }
  }
}