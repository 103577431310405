#breadcrumbs {
  border-top: solid 1px #000;
  font-size: 12px;

  margin-bottom: 30px;
  padding-top: 5px;

  a {

    color: #000;
  }

  strong {
    font-weight: normal;
    color: $pink;
  }
}