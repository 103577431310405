#frontpage-stores {
  margin-bottom: 15px;
  border-top: solid 1px #000;

  ul {
    //@extend .row;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

  }

  li {
    display: block;

	@extend .col-sm-4;
    @extend .col-xs-6;

    @media (min-width: $screen-md) {
      display: inline-block;
      width: calc((100% - 30px) / 7);

    }

    vertical-align: top;
  }

  strong {
    color: $pink;
  }

  .store-description, .store-businesshours {
    display: block;
    margin-bottom: 15px;
  }
}