body.home {

  /**
   * Lijnen ter scheiding van elementen binnen frontpage
   */
  #frontpage-selector-main, #frontpage-content, #frontpage-stores {
    border-top: solid 1px #000;
  }

  #frontpage-selector-main, #frontpage-selector-rentals, #frontpage-stores {
    h2 {
      @extend .typo-header-pink;
      text-align: center;
      margin: 30px 0;
    }
  }

  #frontpage-content {
    padding-top: 30px;
    padding-bottom: 25px;

    h1 {
      @extend .typo-header-pink;
      margin: 0;
      margin-bottom: 30px;
      text-align: center;
    }

    h2, h3 {
      color: $pink;
      font-weight: bold;
    }

    h2 {
      font-size: 22px;
    }
    h3 {
      font-size: 18px;
    }
  }

  @import 'frontpage/selector';
  @import 'frontpage/promotions';
}
@import 'frontpage/stores';