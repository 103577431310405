$font-family-base: 'Open Sans', sans-serif;

//$grid-gutter-width: 80px;
//$container-large-desktop: 1090px + $grid-gutter-width;

/**
 * Bovenste navigatie
 */
$navbar-inverse-color: white;
$navbar-inverse-bg: transparent;
$navbar-inverse-border: transparent;
$navbar-inverse-link-color: white;

/**
 * Hoofdnavigatie
 */
//$navbar-default-color:
$navbar-default-bg: transparent;
$navbar-default-border: transparent;


$rounded-borders: 10px;