.navbar {
  border-radius: 0;
  margin-bottom: 0;

  &#mobile-menu {
    @extend .visible-xs-block;
    background-color: $pink;
  }

  // Bovenste navigatie
  &#topmenu {
    @extend .hidden-xs;
    min-height: 26px;

    .navbar-nav {
      & > li > a {
        font-size: 13px;
        line-height: 16px;
        padding: 10px 15px;
        font-weight: bold;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  // Hoofdnavigatie
  &.navbar-default {
    @extend .hidden-xs;
    position: absolute;
    bottom: 0;

    .navbar-nav {
      & > li {

        &.active, &.current-menu-ancestor {
          & > a {
            color: $pink;
          }
        }

        & > a {
          text-transform: uppercase;
          color: #000;
          font-weight: bold;

          &:hover {
            color: $pink;
          }
        }
      }
    }
  }
}

.navbar .navbar-nav.navbar-center {
  display: inline-block;
  float: none;
  vertical-align: top;
}

.navbar .navbar-collapse {
  text-align: center;
}