.btn {

  &.btn-transparent {
    color: #fff;
    border-color: #fff;
    text-transform: uppercase;
    font-weight: bold;
  }

  &.btn-pink {
    color: #fff;
    background-color: $pink;
    text-transform: uppercase;
    font-weight: bold;
  }

  &.btn-green {
    color: #fff;
    background-color: $green;
    text-transform: uppercase;
    font-weight: bold;
  }
}